import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { TabTitle } from "../utils/title";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useState } from "react";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import AddIcon from '@mui/icons-material/Add';

const Works = () => {
  TabTitle("Works - Maude Deslauriers");

  const [openSection, setOpenSection] = useState(null); // Controls which section is open

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <>
      {" "}
      <Container>
        <Main>
          <StyledAccordion
            expanded={openSection === "paintings"}
            sx={{ margin: "0" }}
          >
            <StyledAccordionSummary
              sx={{
                margin: "0",
                padding: "0",
                "&.Mui-expanded": {
                  minHeight: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "12px 0",
                },
              }}
              // expandIcon={<AddIcon style={{ fontSize: '2rem', fontWeight: 'bold', color: "black" }} />}
              onClick={() => toggleSection("paintings")}
            >
              Paintings
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <SmallNavLink to="/paintings/2024">2024</SmallNavLink>
              <SmallNavLink to="/paintings/2022">2022</SmallNavLink>
              <SmallNavLink to="/paintings/2021">2021</SmallNavLink>
            </StyledAccordionDetails>
          </StyledAccordion>
        </Main>

        <Main>
          <StyledAccordion
            expanded={openSection === "works-on-paper"}
            sx={{ margin: "0" }}
          >
            <StyledAccordionSummary
              sx={{
                margin: "0",
                padding: "0",
                "&.Mui-expanded": {
                  minHeight: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "12px 0",
                },
              }}
              // expandIcon={<ExpandMoreIcon />}
              onClick={() => toggleSection("works-on-paper")}
            >
              Works on Paper
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <SmallNavLink to="/works-on-paper/2023">2023</SmallNavLink>
              <SmallNavLink to="/works-on-paper/2022">2022</SmallNavLink>
            </StyledAccordionDetails>
          </StyledAccordion>
        </Main>

        <Main>
          <StyledAccordion sx={{ margin: "0" }}>
            <StyledNavLink to="/installation-views">
              <AccordionSummary sx={{ margin: "0", padding: "0" }}>
                Installation Views
              </AccordionSummary>
            </StyledNavLink>
          </StyledAccordion>
        </Main>
      </Container>
    </>
  );
};

const StyledAccordion = styled(Accordion)`
  @media (max-width: 600px) {
    font-size: 1.2em;
  }
`;

const SmallNavLink = styled(NavLink)`
  display: block; // Stack the links vertically
  padding: 6px 0;
  width: fit-content; // avoids the hover effect stretching across full width contains to content

  font-family: "Open Sans", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 1.2em;
  border-bottom: solid 1.1px black;

  @media (min-width: 601px) {
    &:hover {
      opacity: 0.5;
      font-style: italic;
    }
  }

  @media (max-width: 600px) {
    font-size: 1.1em;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  font-family: "Open Sans", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 1.3em;

  @media (min-width: 601px) {
    &:hover {
      opacity: 0.5;
      font-style: italic;
    }
  }

  @media (max-width: 600px) {
    font-size: 1.1em;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)``;

const Main = styled.div`
  border-bottom: solid 1.4px black;
  /* padding: 10px 0px; */
`;
const Container = styled.div`
  padding: 50px;
  margin: 0% 17%;
  margin-top: 100px;
  @media (max-width: 600px) {
    margin: 0% 10%;
    padding: 10px;
    margin-top: 100px;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-family: "Open Sans", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 1.3em;

  @media (min-width: 601px) {
    :hover {
      opacity: 0.5;
      font-style: italic;
      /* boxShadow: "none", */
    }
  }

  @media (max-width: 600px) {
    font-size: 1.1em;
  }
`;

export default Works;
