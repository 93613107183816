import styled from "styled-components";
import { TabTitle } from "../utils/title";

const About = () => {
  TabTitle("Bio - Maude Deslauriers");

  return (
    <>
      <Main>
        <Container>
          <Text>
            Maude Deslauriers’s recent work explores the abstract notion of form
            as a link between the natural world, the body, and consciousness.
            Her use of painting and drawing as primary mediums reaffirms the
            participatory relationship of the sensuous body with nature. Through
            revisions of botany, philosophy, and mythology, her formal research
            seeks to establish a language that recenters the natural world as
            both the frame and the subject of a lived experience.
          </Text>
          <br />
          <Text>
            Deslauriers lives and works in Tiohtià:ke / Montreal. She holds an
            MFA in painting and drawing (Concordia University, 2022), a BFA in
            studio arts (Concordia University, 2014), and a DEC in contemporary
            dance (Cégep St-Laurent, 2007). She has participated in artist
            residencies at Artscape (Toronto), the Vermont Studio Center
            (Vermont) and Foundation Obras (Portugal). Her work was presented in
            a solo exhibition at Duran Mashaal gallery (Montreal, 2024) and in
            group exhibitions in Canada and in the United-Sates.
          </Text>
        </Container>
        <Break>- - - - - -</Break>
        <Container>
          <Text>
            Le travail récent de Maude Deslauriers explore la notion abstraite
            de la forme comme lien entre le monde naturel, le corps et la
            conscience. Son utilisation de la peinture et du dessin comme
            médiums principaux réaffirme la relation participative du corps
            sensuel avec la nature. À travers une révision de la botanique, de
            la philosophie et de la mythologie, ses recherches formelles
            cherchent à établir un langage qui recentre le monde naturel comme
            cadre et sujet d’une expérience vécue.
          </Text>
          <br />
          <Text>
            Deslauriers vit et travaille à Tiohtià:ke / Montréal. Elle est
            titulaire d’une maîtrise en peinture et dessin (Université
            Concordia, 2022), d’un baccalauréat en beaux-arts (Université
            Concordia, 2014) et d’un DEC en danse contemporaine (Cégep
            St-Laurent, 2007). Elle a participé à des résidences à Artscape
            (Toronto), au Vermont Studio Center (États-Unis) et à la Fondation
            Obras (Portugal). Son travail a fait l’objet d’une exposition
            individuelle à la galerie Duran Mashaal (Montréal, 2024) et
            d’exposition de groupes au Canada ainsi qu’aux États-Unis.
          </Text>
        </Container>
      </Main>
    </>
  );
};

const Main = styled.div`
  padding: 5% 0;
`;

const Container = styled.div`
  margin: 3% 20%;
  /* border-bottom: solid 1px rgba(0,0,0,0.15);  */
  /* padding-bottom: 3%; */
  /* border-bottom: solid 1px black; */
  text-align: left;
  @media (max-width: 600px) {
    margin: 2% 5%;
  }
`;

const Break = styled.div`
  margin: 0% 20%;

  @media (max-width: 600px) {
    margin: 2% 5%;
  }
`;

const Text = styled.div`
  /* height: 20vh; */
  font-family: "Open Sans", sans-serif;
  font-size: 1em;

  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

export default About;
