import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const Views = () => {
  TabTitle("Installation Views - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <TextMainFirst>
        {" "}
        <Text>
          Views of the exhibition <i>Under the Spell</i>, Duran Mashaal,
          Montreal, QC, 2024.
        </Text>
      </TextMainFirst>

      <Lightbox Wrapper={WrapperTwo} src="/images/i1.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2001"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/i1.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/i2.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/i2.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/i3.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/i3.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/i4.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2001"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/i4.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/i5.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2001"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/i5.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          Views of the exhibition <i>Body Talk</i>, MFA Gallery, Concordia
          University, Montreal, QC, 2022.
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/17.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/17.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/18.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/18.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/19.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/19.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/20.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="1999"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/20.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          Views of the exhibition <i>Autopoietic Drawing Series</i>, MFA
          Gallery, Concordia University, Montreal, QC, 2022.
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/06A.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="1000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/06A.jpg"
          alt=""
        />
      </Lightbox>

      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextMainFirst = styled.div`
  margin-top: 50px;
`;
const TextMain = styled.div`
  margin-top: 100px;
`;

const WrapperTwo = styled.div`
  margin: 1% 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 600px) {
    margin: 1% 3%;
  }
`;

const Img = styled(motion.img)`
  /* float: left; */
  width: 55%;
  height: 60%;
  object-fit: cover;
  padding-top: 1px;
  cursor: pointer;
  clip-path: inset(2px 0.5px);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black;  */
  padding: 2px 20px;
  margin-left: 2%;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
    margin: 0% 3%;
    padding: 2px 0px;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 22px;
  }
`;

export default Views;
