import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const WorksOnPaper2023 = () => {
  TabTitle("Works on Paper 2023 - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <TextMainFirst>
        <Text>
          <span style={{ fontStyle: "italic" }}>Full Bloom</span>, 29 x 22 in,
          Flashe and graphite on paper, 2023
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Full Bloom </span>
          (detail)
        </Text>
      </TextMainFirst>

      <Lightbox Wrapper={WrapperTwo} src="/images/0001.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6720"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0001.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/0001A.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6179"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0001A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Fossils</span>, 29 x 22 in,
          Flashe and graphite on paper, 2023.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Fossils</span> (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/0002.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6720"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0002.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/0002A.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6720"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0002A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Passage</span>, 12 ¼ x 20 ¾ in,
          Flashe and ink on paper, 2023.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Passage</span> (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/0003.jpg">
        <Img
          loading="lazy"
          width="6720"
          height="4480"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0003.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/0003A.jpg">
        <Img
          loading="lazy"
          width="5639"
          height="4478"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0003A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Waiting for the Sun</span>, 29 x
          22 in, Flashe and ink on paper, 2023.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Waiting for the Sun</span>{" "}
          (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/0004.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6720"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0004.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/0004A.jpg">
        <Img
          loading="lazy"
          width="4480"
          height="6720"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/0004A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextMainFirst = styled.div`
  margin-top: 50px;
  padding-bottom: 15px;
`;
const TextMain = styled.div`
  margin-top: 100px;
  padding-bottom: 15px;
`;

const WrapperTwo = styled.div`
  margin: 1% 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    margin: 1% 3%;
  }
`;

const Img = styled(motion.img)`
  /* float: left; */
  width: 55%; /* Adjust width to make images smaller and fit side by side */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  padding-top: 1px;
  cursor: pointer;
  clip-path: inset(2px 0.5px);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black;  */
  padding: 2px 20px;
  margin-left: 2%;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
    margin: 0% 3%;
    padding: 2px 0px;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 22px;
  }
`;

export default WorksOnPaper2023;
