import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import { TabTitle } from "../utils/title";

const Cv = () => {
  TabTitle("CV - Maude Deslauriers");

  return (
    <>
      <Container>
        <ContactContainer>
          <ContactText>
            Email:{" "}
            <Anchor2 href="mailto:maudedeslauriers8@gmail.com">
              maudedeslauriers8@gmail.com
            </Anchor2>
          </ContactText>
          <ContactText>
            Instagram:{" "}
            <Anchor2 href="https://www.instagram.com/m_deslau/">
              @m_deslau
            </Anchor2>
          </ContactText>
        </ContactContainer>

        <DetailContainer>
          <TextBold>Education</TextBold>
          <Text>
            2022 Master of Fine Arts (MFA) in Painting and Drawing, Concordia
            University, Montreal, QC
          </Text>
          <Text>
            2014 Bachelor of Fine Arts (BFA), Concordia University, Montreal, QC
          </Text>
          <Text>
            2009 Cours préparatoires, Les Ateliers de danse moderne de Montréal
            (LADMMI), QC
          </Text>
          <Text>
            2007 Diplôme d’études collégiales (DEC), Danse contemporaine, CÉGEP
            Saint-Laurent, Montreal, QC
          </Text>

          <TextBold>Solo Exhibitions</TextBold>
          <Text>
            2024 <i>Under the Spell</i>, Duran Mashaal, Montreal, QC
          </Text>
          <Text>
            2022 <i>Body Talk</i>, thesis exhibition, MFA Gallery, Concordia
            University, Montreal, QC
          </Text>

          <Text>
            2019 <i>World of the Unknown</i>, Project Space 311, Ottawa, ON
          </Text>

          <TextBold>Group Exhibitions</TextBold>
          <Text>
            2024 <i>Future Fair</i>, with Duran Mashaal, New York, NY
          </Text>
          <Text>
            2024 <i>Plural</i>, with Duran Mashaal, Montreal, QC
          </Text>
          <Text>
            2023 <i>Art Toronto</i>, with Duran Mashaal, Toronto, ON
          </Text>

          <Text>
            2022{" "}
            <Anchor href="https://foreman.ubishops.ca/fr/les-yeux-dans-leau/">
              <i>Les yeux dans l'eau</i>
            </Anchor>
            , Foreman Gallery, Sherbrooke, QC
          </Text>
          <Text>
            2021{" "}
            <Anchor href="https://farbywide.com/past-projects/quiet-fire/1">
              <i>Quiet Fire</i>
            </Anchor>
            , online benefit exhibition for Seeding Sovereignty
          </Text>
          <Text>
            2019{" "}
            <Anchor href="http://larochejoncas.com/summer-in-the-city-iii/">
              <i>Summer in the City III</i>
            </Anchor>
            , Galerie Laroche/Joncas, Montreal, QC
          </Text>
          <Text>
            2019 <i>Maureen IV: Studio Arts MFA Exhibition</i>, Galerie Yves
            Laroche, Montreal, QC{" "}
          </Text>
          <Text>
            2017 <i>Circles Without Breaks</i>, Local Project Art Space, Long
            Island City, USA
          </Text>
          <Text>
            2017{" "}
            <Anchor href="http://archives.articule.org/2013-2020/?q=en/projects/the-garden-of-speculations">
              <i>The Garden of Speculation</i>
            </Anchor>
            , Articule, Montreal, QC
          </Text>

          <Text>
            2015 <i>Consumed</i>, Slide Room Gallery, Victoria, British Columbia
          </Text>
          <Text>
            2015 <i>The Ministry of Information</i>, CK2 Gallery, Montreal, QC
          </Text>

          <TextBold>Residencies</TextBold>
          <Text>2023 Atelier Silex, Trois-Rivières, QC</Text>
          <Text>2017 Vermont Studio Center, Vermont, USA</Text>
          <Text>2015 Luminous Bodies, Gibraltar Point, Toronto, ON</Text>

          <TextBold>Grants and Awards</TextBold>
          <Text>
            2023 Research and Creation Grant, Canada Council of the Arts
          </Text>

          <Text>2019 Fine Arts Travel Award, Concordia University</Text>
          <Text>
            2019 Tom Hopkins Memorial Graduate Award, Concordia University
          </Text>

          <TextBold>Teaching Experience</TextBold>
          <Text>
            2023 Drawing Teacher, Drawing Flora and Drawing I, Westmount Visual
            Arts Centre
          </Text>

          <Text>
            2021 Drawing Instructor, Drawing 200, Concordia University
          </Text>
          <Text>
            2020 Teaching Assistant, Drawing 200, Concordia University
          </Text>
          <Text>
            2019 Teaching Assistant, Drawing 200, Concordia University
          </Text>

          <TextBold>Publications & Press</TextBold>
          <Text>
            2022 Joëlle Dubé,{" "}
            <Anchor href="https://esse.ca/compte-rendu/les-yeux-dans-leau/">
              <i>Les yeux dans l'eau</i>
            </Anchor>
            , Exhibition review, Esse Magazine, (online)
          </Text>
          <Text>
            2017 Megan Mericle,{" "}
            <i>Bienvenue dans notre jardin des délices terrestres</i>,
            Exhibition Text, Articule, Montreal, QC.
          </Text>
        </DetailContainer>
      </Container>
      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextBold = styled.div`
  font-weight: bold;
  padding: 2% 0%;
  margin-top: 3%;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1em;
`;

const DetailContainer = styled.div`
  padding: 3% 0%;
`;

const ContactContainer = styled.div`
  padding-bottom: 5%;
  border-bottom: solid 1px black;
`;

const Anchor = styled.a`
  color: inherit;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  :hover {
    border-bottom: solid 1px black;
    font-style: italic;
  }
`;
const Anchor2 = styled.a`
  text-decoration: none;
  color: inherit;
  :hover {
    text-decoration: underline;
  }
`;

const ContactText = styled.div`
  font-size: 1.1em;
  font-family: "Open Sans", sans-serif;
  padding: 1px 0px;
  @media (max-width: 600px) {
    font-size: 1.1em;
  }
`;
const Container = styled.div`
  margin: 5% 25%;
  /* border-top: solid 1px rgba(0,0,0,0.15);  */
  text-align: justify;
  @media (max-width: 600px) {
    font-size: 0.9em;
    margin: 6% 3%;
  }
`;

const Text = styled.div`
  /* height: 50vh; */
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;
  line-height: 1.8;

  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  /* margin-top: 70px; */
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.6em;
    margin-left: 3%;
    margin-top: 5%;
  }
`;

export default Cv;
