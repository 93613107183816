import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const WorksOnPaper2022 = () => {
  TabTitle("Works on Paper 2022 - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <Lightbox Wrapper={WrapperOne} src="/images/01A.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          // viewport={{ once: true}}
          src="/images/01A.jpg"
          alt=""
        />{" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Eater </span> (Autopoietic
          Drawing Series), flashe on paper, 9 x 12 in, 2022.
        </Text>
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/02A.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/02A.jpg"
          alt=""
        />{" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Pursuit-Evasion </span>{" "}
          (Autopoietic Drawing Series), flashe on paper, 9 x 12 in, 2022.
        </Text>
      </Lightbox>

      <Lightbox Wrapper={WrapperOne} src="/images/03A.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/03A.jpg"
          alt=""
        />{" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Hypersensitive </span>{" "}
          (Autopoietic Drawing Series), flashe on paper, 9 x 12 in, 2022.
        </Text>
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/04A.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/04A.jpg"
          alt=""
        />{" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Inner Circle </span>{" "}
          (Autopoietic Drawing Series), flashe on paper, 9 x 12 in, 2022.
        </Text>
      </Lightbox>

      <Lightbox Wrapper={WrapperOne} src="/images/05A.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/05A.jpg"
          alt=""
        />{" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Closed Door </span> (Autopoietic
          Drawing Series), flashe on paper, 9 x 12 in, 2022.
        </Text>
      </Lightbox>

      {/* <Container>
        <Lightbox Wrapper={WrapperThree} src="/images/06A.jpg">
          <Img
            loading="lazy"
            width="1500"
            height="1000"
            variants={AnimateImgVariant}
            initial="hidden"
            whileInView="visible"
            src="/images/06A.jpg"
          />
        </Lightbox>
        <TextTwo>
          View of the exhibition Autopoietic Drawing Series, MFA Gallery,
          Concordia University, 2022.
        </TextTwo>
      </Container> */}

      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

// const TextTwo = styled.div`
//   padding: 3% 0%;
//   margin: 0% 20%;
//   font-family: "Open Sans", sans-serif;
//   font-size: 0.9em;
//   @media (max-width: 600px) {
//     font-size: 0.8em;
//     margin: 0%;
//     padding: 0%;
//   }
// `;

const WrapperOne = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 3% 0;
  margin: 0% 20%;
  @media (max-width: 600px) {
    margin: 5%;
  }
`;

const WrapperTwo = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 3% 0;
  margin: 0% 20%;
  @media (max-width: 600px) {
    margin: 5%;
  }
`;

// const WrapperThree = styled.div`
//   display: flex;
//   justify-content: center;
//   @media (max-width: 600px) {
//     /* margin: 5%; */
//     /* padding: 0%; */
//   }
// `;

const Img = styled(motion.img)`
  cursor: pointer;
  width: 45%;
  padding-bottom: 10px;
  height: auto;
  clip-path: inset(1.5px 0.5px);
  @media (max-width: 600px) {
    height: auto;
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black; */
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;
  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

// const Container = styled.div`
//   padding: 3% 0%;
//   /* border-bottom: solid 1px rgba(0,0,0,0.15); */
//   @media (max-width: 600px) {
//     margin: 0% 5%;
//   }
// `;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 20%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 5%;
  }
`;

export default WorksOnPaper2022;
