import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const Paintings2021 = () => {
  TabTitle("Paintings 2021 - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <TextMainFirst>
        <Text>
          <span style={{ fontStyle: "italic" }}>Blue River</span>, sand, flashe,
          oil on muslin over wood, 48 x 90 x 1 in, 2021.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Blue River </span> (detail).
        </Text>
      </TextMainFirst>
      <Lightbox Wrapper={WrapperTwo} src="/images/01.jpg">
        <Img
          loading="lazy"
          width="2000"
          height="1333"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/01.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/02.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="667"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/02.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Larmelette</span>, sand, flashe,
          oil on muslin over wood, 49 x 20 x 1 in, 2021.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Larmelette</span> (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/03.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="1150"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/03.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/04.jpg">
        <Img
          loading="lazy"
          width="667"
          height="1000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/04.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextMainFirst = styled.div`
  margin-top: 50px;
  padding-bottom: 15px;
`;
const TextMain = styled.div`
  margin-top: 100px;
  padding-bottom: 15px;
`;

const WrapperTwo = styled.div`
  margin: 1% 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 600px) {
    margin: 1% 3%;
  }
`;

const Img = styled(motion.img)`
  /* float: left; */
  width: 55%;
  height: 60%;
  object-fit: cover;
  padding-top: 1px;
  cursor: pointer;
  clip-path: inset(2px 0.5px);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black;  */
  padding: 2px 20px;
  margin-left: 2%;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
    margin: 0% 3%;
    padding: 2px 0px;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 22px;
  }
`;

export default Paintings2021;
