import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const Paintings2022 = () => {
  TabTitle("Paintings 2022 - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <TextMainFirst>
        {" "}
        <Text>
          <span style={{ fontStyle: "italic" }}>Illusion</span>, marble dust,
          sand, flashe, oil on muslin over wood, 95 x 41 x 1 in, 2022.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Illusion</span> (detail).
        </Text>
      </TextMainFirst>

      <Lightbox Wrapper={WrapperTwo} src="/images/05.jpg">
        <Img
          loading="lazy"
          width="1684"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/05.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/06.jpg">
        <Img
          loading="lazy"
          width="801"
          height="1200"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/06.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Nested Dream</span>, marble
          dust, sand, flashe, oil on muslin over wood, 60,5 x 45,5 x 1,5 in,
          2022.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Nested Dream</span> (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/07.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="1267"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/07.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/08.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/08.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Good Morning, Midnight</span>,
          sand, flashe, oil on muslin over wood, 95 x 45 x 1 in, 2022.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Good Morning, Midnight</span>{" "}
          (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/09.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/09.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/10.jpg">
        <Img
          loading="lazy"
          width="867"
          height="1300"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/10.jpg"
          alt=""
        />{" "}
      </Lightbox>

      {/* 

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Serpentine Dance</span>, sand,
          flashe, oil on muslin over wood, 80 x 38 x 1 in, 2022.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Serpentine Dance</span>{" "}
          (detail).
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Serpentine Dance</span>{" "}
          (detail).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/11.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="1001"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/11.jpg"
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/12.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/12.jpg"
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/13.jpg">
        <Img
          loading="lazy"
          width="1500"
          height="2250"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/13.jpg"
        />{" "}
      </Lightbox> */}

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Sisters</span>, sand, flashe,
          oil on muslin over wood, 22 x 29 x 1 in, 2022.
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/14.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/14.jpg"
          alt=""
        />{" "}
      </Lightbox>
      {/* 

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Soul System </span>, flashe, oil
          on muslin over wood, 16 x 17 x 1 in, 2022.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Soul System </span>(Profile
          view).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/15.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="1000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/15.jpg"
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/16.jpg">
        <Img
          loading="lazy"
          width="1000"
          height="1000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/16.jpg"
        />{" "}
      </Lightbox> */}

      {/* <TextMain>
        <Text>
          Views of the exhibition Body Talk, MFA Gallery, Concordia University,
          2022.
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/17.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/17.jpg"
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/18.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/18.jpg"
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/19.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="2000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/19.jpg"
        />{" "}
      </Lightbox>

      <Lightbox Wrapper={WrapperTwo} src="/images/20.jpg">
        <Img
          loading="lazy"
          width="3000"
          height="1999"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/20.jpg"
        />{" "}
      </Lightbox> */}
      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextMainFirst = styled.div`
  margin-top: 50px;
  padding-bottom: 15px;
`;
const TextMain = styled.div`
  margin-top: 100px;
  padding-bottom: 15px;
`;

const WrapperTwo = styled.div`
  margin: 1% 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 600px) {
    margin: 1% 3%;
  }
`;

const Img = styled(motion.img)`
  /* float: left; */
  width: 55%;
  height: 60%;
  object-fit: cover;
  padding-top: 1px;
  cursor: pointer;
  clip-path: inset(2px 0.5px);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black;  */
  padding: 2px 20px;
  margin-left: 2%;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
    margin: 0% 3%;
    padding: 2px 0px;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 22px;
  }
`;

export default Paintings2022;
