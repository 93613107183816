import React from "react";
import "./global.css";
import { Routes, Route } from "react-router-dom";
import DrawerAppBar from "./components/NavBar";
import Home from "./components/Home";
import Works from "./components/Works";
import Bio from "./components/Bio";
import Cv from "./components/Cv";
import Paintings2021 from "./components/Paintings2021";
import Paintings2022 from "./components/Paintings2022";
import Paintings2024 from "./components/Paintings2024";
import ScrollToTop from "./components/ScrollToTop";
import Views from "./components/Views";
import WorksOnPaper2022 from "./components/WorksOnPaper2022";
import WorksOnPaper2023 from "./components/WorksOnPaper2023";

const App = () => {
  return (
    <div>
      <ScrollToTop />
      <DrawerAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/works" element={<Works />} />
        <Route path="/bio" element={<Bio />} />
        <Route path="/cv" element={<Cv />} />
        <Route path="/paintings/2021" element={<Paintings2021 />} />
        <Route path="/paintings/2022" element={<Paintings2022 />} />
        <Route path="/paintings/2024" element={<Paintings2024 />} />

        <Route path="/works-on-paper/2022" element={<WorksOnPaper2022 />} />
        <Route path="/works-on-paper/2023" element={<WorksOnPaper2023 />} />

        <Route path="/installation-views" element={<Views />} />
      </Routes>
    </div>
  );
};

export default App;
