import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import { TabTitle } from "../utils/title";

const Home = () => {
  TabTitle("Maude Deslauriers");

  return (
    <>
      <Container>
        <HomeImg
          src="/images/i5.jpg"
          // loading="lazy"
          width="3000"
          height="2001"
          alt=""
        />
        <HomeImg2
          src="/images/03.jpg"
          // loading="lazy"
          width="1000"
          height="1500"
          alt=""
        />
      </Container>
      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px; /* Added padding */

  height: 75vh; /* Adjust the height as needed to fit within the viewport */

  /* Optional: ensure the container doesn't overflow */
  overflow: hidden;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const HomeImg = styled.img`
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  max-width: 100%; /* Fits container width */
  height: auto; /* Maintains aspect ratio */

  /* To ensure the image fits within the container's height while maintaining its aspect ratio */
  max-height: 100%;
  object-fit: contain;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* @media (min-width: 601px) {

 

  } */

  @media (max-width: 600px) {
    display: none;
  }
`;

const HomeImg2 = styled.img`
  @media (max-width: 600px) {
    width: 90%;
    height: auto;
    clip-path: inset(1.5px 0.5px);
    // added height for lazy loading
  }

  @media (min-width: 601px) {
    display: none;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 10px;
  margin-top: 10px;
  font-size: 0.7em;
  margin-left: 6%;
  @media (max-width: 600px) {
    font-size: 0.6em;
    margin-left: 22px;
  }
`;
export default Home;
