import styled from "styled-components";
import { MdOutlineCopyright } from "react-icons/md";
import Lightbox from "./Lightbox";
import { TabTitle } from "../utils/title";
import { motion } from "framer-motion";

const Paintings2021 = () => {
  TabTitle("Paintings 2024 - Maude Deslauriers");

  const AnimateImgVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      // y: -50,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <TextMainFirst>
        <Text>
          <span style={{ fontStyle: "italic" }}>
            Floating into the night (of the Vernal Equinox)
          </span>
          , Flashe and oil on linen over Baltic birch panel, 16,45 x 29 in, 2024
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>
            Floating into the night (of the Vernal Equinox){" "}
          </span>
          (profile view)
        </Text>
      </TextMainFirst>

      <Lightbox Wrapper={WrapperTwo} src="/images/001.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/001.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/001A.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/001A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Take a Piece</span>, Flashe and
          oil on linen over Baltic birch panel, 23,60 x 44,05 in, 2024.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Take a Piece</span> (profile
          view).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/002.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/002.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/002A.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/002A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Song for an air drifter</span>,
          Flashe and oil on linen over Baltic birch panel, 31 x 62 in, 2024.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Song for an air drifter</span>{" "}
          (profile view).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/003.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/003.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/003A.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/003A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Wind Portal</span>, Flashe and
          oil on linen over Baltic birch panel, 21 x 38,75 in, 2024.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Wind Portal</span> (profile
          view).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/004.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/004.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/004A.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/004A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <TextMain>
        <Text>
          <span style={{ fontStyle: "italic" }}>Wind portal II</span>, Flashe
          and oil on linen over Baltic birch panel, 21,30 x 38,50 in, 2024.
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>Wind portal II</span> (profile
          view).
        </Text>
      </TextMain>

      <Lightbox Wrapper={WrapperTwo} src="/images/005.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/005.jpg"
          alt=""
        />{" "}
      </Lightbox>
      <Lightbox Wrapper={WrapperTwo} src="/images/005A.jpg">
        <Img
          loading="lazy"
          width="2001"
          height="3000"
          variants={AnimateImgVariant}
          initial="hidden"
          whileInView="visible"
          src="/images/005A.jpg"
          alt=""
        />{" "}
      </Lightbox>

      <Copyright>
        Copyright <MdOutlineCopyright /> 2021-2024 Maude Deslauriers. All Rights
        Reserved.
      </Copyright>
    </>
  );
};

const TextMainFirst = styled.div`
  margin-top: 50px;
  padding-bottom: 15px;
`;
const TextMain = styled.div`
  margin-top: 100px;
  padding-bottom: 15px;
`;

const WrapperTwo = styled.div`
  margin: 1% 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    margin: 1% 3%;
  }
`;

const Img = styled(motion.img)`
  /* float: left; */
  width: 55%; /* Adjust width to make images smaller and fit side by side */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  padding-top: 1px;
  cursor: pointer;
  clip-path: inset(2px 0.5px);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Text = styled.div`
  /* border-bottom: solid 1px black;  */
  padding: 2px 20px;
  margin-left: 2%;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
    margin: 0% 3%;
    padding: 2px 0px;
  }
`;

const Copyright = styled.div`
  font-family: "Roboto Mono", monospace;
  padding-bottom: 20px;
  margin-top: 70px;
  font-size: 0.7em;
  margin-left: 10%;
  @media (max-width: 600px) {
    font-size: 0.7em;
    margin-left: 22px;
  }
`;

export default Paintings2021;
