import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { IoLogoInstagram } from "react-icons/io";

import { Link, NavLink } from "react-router-dom";

import { FiMenu } from "react-icons/fi";
import styled from "styled-components";

const drawerWidth = 220;

//ADD Header titles here
const navItems = ["WORKS", "CV", "BIO"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "50px" }}
    >
      <CloseIcon sx={{ fontSize: 40, p: 2, float: "right" }} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              component={Link}
              to={`/${item.toLowerCase()}`}
            >
              {/* //Change font etc for the drawer here below   */}

              <ListItemText
                primaryTypographyProps={{ fontSize: "22px" }}
                primary={item}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  //Change fontSize for Maude mobile header here below  AND hover effect

  const buttonSX = {
    fontFamily: "Open Sans",
    fontWeight: "",
    padding: { xs: 0, sm: 0 },
    fontSize: { xs: 18, sm: "inherit" },
    color: "black",
    boxShadow: "none",
    cursor: "default",

    "@media(min-width: 601px)": {
      "&:hover": {
        // opacity: 0.4,
        // fontStyle: "italic",
        // boxShadow: "none",
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent",
        },
      },
    },
  };

  return (
    //TO CHANGE HEIGHT OF NAVBAR DO IT HERE BELOW
    <Box sx={{ display: "flex", height: "50px" }}>
      <AppBar
        component="nav"
        sx={{
          color: "black",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            fontSize: 17,
            px: { xs: 0 },
            mx: { xs: 1, md: 4 },
            color: "black",
          }}
        >
          <Typography
            // fontWeight="fontWeightBold"

            // variant="h6"
            component="div"
            sx={{
              fontSize: { xs: 17, sm: 17 },
              padding: { xs: 2, sm: 3 },

              fontWeight: 400,
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
            }}
          >
            {/* TEST */}

            <Button disableRipple sx={buttonSX}>
              <Link style={{ textDecoration: "none", color: "black" }} to="/">
                {" "}
                MAUDE DESLAURIERS
              </Link>
            </Button>
          </Typography>

          <IconButton
            disableTouchRipple={true}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ pl: 1, ml: "10%", display: { sm: "none" } }}
          >
            <FiMenu size={34} />
          </IconButton>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Div key={item}>
                <Button disableRipple sx={buttonSX}>
                  <StyledNavLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={`/${item.toLowerCase()}`}
                  >
                    {item}
                  </StyledNavLink>
                </Button>
              </Div>
            ))}
          </Box>

          <Anchor
            href="https://www.instagram.com/m_deslau/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <IoLogoInstagram1 fontWeight={100} size={24} />
          </Anchor>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  box-shadow: none;

  &.active {
    /* font-weight: bold; */
    border-bottom: 1px solid black;
  }

  @media (min-width: 601px) {
    &:hover {
      opacity: 0.6;
      font-style: italic;
      box-shadow: none;
    }
  }
`;

const IoLogoInstagram1 = styled(IoLogoInstagram)`
  color: black;

  :hover {
    color: purple;
  }
`;
const Anchor = styled.a`
  cursor: pointer;
  margin-bottom: -5px;
  /* :hover {
color: red;
} */

  @media (max-width: 600px) {
    display: none;
  }
`;

const Div = styled.div`
  display: inline-block;
  padding: 0px 20px;
`;
export default DrawerAppBar;
