import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

const Lightbox = ({ children, src, alt, Wrapper = "div" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper onClick={toggleIsOpen}>
      {children}
      {isOpen ? (
        <BoxStyle onClick={toggleIsOpen}>
          <AiOutlineClose
            size="35px"
           
            style={{
              position: "absolute",
              top: "20px",
              right: "3%",
         
            }}
          />

          <Img
            src={src}
            alt={alt}
            // style={{
            //   height: "90%",
           
              
            //   width: "auto",
            //  padding: "2%",
             
            // }}
          />
        </BoxStyle>
      ) : null}
    </Wrapper>
  );
};

const Img = styled.img`
height: 90%;
width: auto;
padding: 2%;

@media (max-width: 600px) {

  }


`

const BoxStyle = styled.div`

  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  cursor: pointer;
  z-index: 10001;

  @media (max-width: 600px) {
    display: none;
   
  }
`;

export default Lightbox;
